import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { RepackBySlugOrGuid } from 'apollo/repack/repack.gql';
import PageMetadata from 'components/PageMetadata';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import {
    RepackBySlugOrGuidQuery,
    RepackBySlugOrGuidQueryVariables,
} from 'types/generated/graphql';

export const RePackDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data, loading, error } = useQuery<
        RepackBySlugOrGuidQuery,
        RepackBySlugOrGuidQueryVariables
    >(RepackBySlugOrGuid, {
        skip: !getSanitizeSlug(slug),
        variables: { repackSlugOrGuid: getSanitizeSlug(slug) },
    });

    if (loading) {
        return null;
    }

    if (!data?.repack || error) {
        return <PageMetadata />;
    }

    const { repack } = data;

    return (
        <PageMetadata
            imageUrl={repack?.artworkUrl}
            title={repack?.seoMetadata?.title}
            description={repack?.seoMetadata?.description}
        />
    );
};
