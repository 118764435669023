import { t } from '@lingui/macro';
import { SeoType, BrowseSeoType } from 'types/seoType';
import { CONFIG } from 'utils/config';
import { LandrSamplesBrand, LandrBrand, CreatorBrand } from './brands';

const getStaticSeo = () => {
    const defaultSeo: SeoType = {
        title: t`Samples - Sample Packs, Free Loops and One Shots`,
        description: t`Get the best sample packs, free loops, synths, bass, vocals, drum kits and sound libraries with AI's help. Spark ideas with the best royalty-free sounds.`,
        imageUrl: `${CONFIG.VITE_SAMPLES_WEB_URL}/assets/seo/social-default.jpg`,
    };

    const genresSeo: SeoType = {
        title: t`Samples for All Genres - Trap, EDM, Hip-Hop and More`,
        description: t`Get the best samples and loops from every genre. From trap to pop, rock to ambient, browse, discover and download samples today`,
        imageUrl: defaultSeo.imageUrl,
    };

    const instrumentsSeo: SeoType = {
        title: t`Instrument Samples - Drums, Guitar, Keys and More`,
        description: t`Download the best instrument samples and loops from ${LandrSamplesBrand}. Get drums, keys, synths and more. Spark your music production today.`,
        imageUrl: defaultSeo.imageUrl,
    };

    const sfsxSeo: SeoType = {
        title: t`Download Royalty Free Sound Effects (SFX) Samples`,
        description: t`Get access to over 200,000 royalty free sound effects (SFX) for your creative project. Browse the LANDR Samples library of Sound FX in MP3 and Wav format.`,
        imageUrl: defaultSeo.imageUrl,
    };

    const chartsSeo = {
        title: t`Samples Charts - Find Top Sounds and Sample Packs`,
        description: t`Listen to the top trending samples in music production with ${LandrSamplesBrand} Charts, download the best royalty-free samples and sample packs at LANDR. `,
        imageUrl: defaultSeo.imageUrl,
    };

    const exclusivesSeo: SeoType = {
        title: t`Exclusives - Get the Best Sample Packs`,
        description: t`Discover exclusive samples from top music producers and artists`,
        imageUrl: defaultSeo.imageUrl,
    };

    const browseSeo: BrowseSeoType = {
        title: t`Sample Search - Find Samples for Your Tracks`,
        description: t`Find samples fast. Browse sounds with filters or start with a sample search. Get the samples you need and spark inspiration.`,
        header: t`Search`,
        byline: t`Find the right sounds fast. Browse with filters or start with search.`,
        imageUrl: defaultSeo.imageUrl,
    };

    const creatorSharedSeo: SeoType = {
        title: t`Check out this loop I made with ${CreatorBrand} on ${LandrSamplesBrand}`,
        description: t`${CreatorBrand} is the new beat making tool for ${LandrSamplesBrand}. Hear how up to eight loops from ${LandrBrand}'s royalty-free sample library sound together in your browser.`,
        imageUrl: `${CONFIG.VITE_SAMPLES_WEB_URL}/assets/seo/creator-beatmaker.png`,
    };

    const creatorSeo: SeoType = {
        title: t`Free Online Beat Maker - Make a Beat | ${CreatorBrand} by ${LandrBrand}`,
        description: t`Make beats online using real samples with ${LandrBrand} ${CreatorBrand}. Hear how up to eight loops from ${LandrBrand}'s royalty-free sample library sound together in your browser`,
        imageUrl: `${CONFIG.VITE_SAMPLES_WEB_URL}/assets/seo/creator-beatmaker.png`,
    };

    return {
        default: defaultSeo,
        genres: genresSeo,
        instruments: instrumentsSeo,
        charts: chartsSeo,
        exclusives: exclusivesSeo,
        browse: browseSeo,
        creatorShared: creatorSharedSeo,
        creator: creatorSeo,
        sfxs: sfsxSeo,
    };
};

export default getStaticSeo;
