import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { LabelBySlug } from 'apollo/label/label.gql';
import PageMetadata from 'components/PageMetadata';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import {
    LabelBySlugQuery,
    LabelBySlugQueryVariables,
} from 'types/generated/graphql';

export const LabelDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data, loading, error } = useQuery<
        LabelBySlugQuery,
        LabelBySlugQueryVariables
    >(LabelBySlug, {
        variables: { slug: getSanitizeSlug(slug) },
    });

    if (loading) {
        return null;
    }

    if (!data?.labelBySlug || error) {
        return <PageMetadata />;
    }

    const { labelBySlug } = data;

    return (
        <PageMetadata
            title={labelBySlug?.seoMetadata?.title}
            description={labelBySlug?.seoMetadata?.description}
            imageUrl={labelBySlug?.artworkUrl}
        />
    );
};
