export enum UserActions {
    GetUserLocationFailed = 'USER/GET_USER_LOCATION_FAILED',
    GetUserAccountStatusFailed = 'USER/GET_ACCOUNT_STATUS_FAILED',
    GetUserProfileFailed = 'USER/GET_USER_PROFILE_FAILED',
    GetUserCurrencyFailed = 'USER/GET_USER_CURRENCY_FAILED',
    GetUserRemainingCreditsFailed = 'USER/GET_USER_REMAINING_CREDITS_FAILED',
    GetUserAgreementsFailed = 'USER/GET_USER_AGREEMENTS_FAILED',
    AcceptAgreementsFaileD = 'USER/ACCEPT_AGREEMENTS_FAILED',
    GetAccountSummaryFailed = 'USER/GET_USER_ACCOUNT_SUMMARY_FAILED',
    OPEN_AUTH_MODAL = 'USER/OPEN_AUTH_MODAL',
    CLOSE_AUTH_MODAL = 'USER/CLOSE_AUTH_MODAL',
    DO_PRIVATE_ACTION = 'USER/DO_PRIVATE_ACTION',
}
