import { gql } from '@apollo/client';
import {
    META_PAGE_FILTER_FRAGMENT,
    META_PAGE_TOTAL_FRAGMENT,
} from 'apollo/metaPage/metaPage.fragment';
import { SAMPLE_FRAGMENT } from 'apollo/sample/sample.fragment';
import { PACK_EXCERPT_FRAGMENT } from 'apollo/pack/pack.fragment';
import { BRANDTYPE_DETAIL_FRAGMENT } from './brandtype.fragment';

export const PaginatedBrandtypeSamples = gql`
    query PaginatedBrandtypeSamples(
        $brandtypeGuid: ID
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedBrandtypeSamples(
            brandtypeGuid: $brandtypeGuid
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...SampleFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${SAMPLE_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
`;

export const PaginatedBrandtypePacks = gql`
    query PaginatedBrandtypePacks(
        $brandtypeGuid: ID
        $take: Int = 1
        $page: Int = 0
        $query: String
        $offset: Int
    ) {
        paginatedBrandtypePacks(
            brandtypeGuid: $brandtypeGuid
            take: $take
            page: $page
            query: $query
            offset: $offset
        ) {
            data {
                ...PackExcerptFragment
            }
            meta {
                ...MetaPageTotalFragment
                ...MetaPageFilterFragment
            }
            next {
                skip
            }
        }
    }
    ${PACK_EXCERPT_FRAGMENT}
    ${META_PAGE_FILTER_FRAGMENT}
    ${META_PAGE_TOTAL_FRAGMENT}
`;

export const BrandtypeBySlug = gql`
    query BrandtypeBySlug($slug: String) {
        brandtypeBySlug(slug: $slug) {
            ...BrandtypeDetailFragment
        }
    }
    ${BRANDTYPE_DETAIL_FRAGMENT}
`;
