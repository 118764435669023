import { useAuthentication } from 'components/AuthenticationProvider/AuthenticationProvider';
import { useModalContext } from 'contexts/ModalContext/ModalContext';
import { getIsDAWPlugin } from 'helpers';
import { useBreakpoint } from '@landr/maestro';
import React, {
    SetStateAction,
    useState,
    useContext,
    useEffect,
    useCallback,
} from 'react';
import { analytics } from 'utils';
import {
    InteractionSource,
    ModalName,
} from 'utils/analytics/AnalyticsV2Service.generated';
import { noop } from 'utils/noop';
import { useSubscriptionsAndAllowedFeaturesContext } from 'contexts/SubscriptionsAndAllowedFeaturesContext/SubscriptionsAndAllowedFeaturesContext';

export type SweetenerUpsellModalContextType = {
    setIsEligibleForSweetener: React.Dispatch<SetStateAction<boolean>>;
};

export const defaultSweetenerUpsellModalContext = {
    setIsEligibleForSweetener: noop,
};

export const SweetenerUpsellModalContext =
    React.createContext<SweetenerUpsellModalContextType>(
        defaultSweetenerUpsellModalContext,
    );

export const useSweetenerUpsellModalContext =
    (): SweetenerUpsellModalContextType =>
        useContext(SweetenerUpsellModalContext);

export const SweetenerUpsellModalProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const [isEligibleForSweetener, setIsEligibleForSweetener] = useState(false);
    const [
        counterToDisplaySweetenerMobileModal,
        incrementCounterToDisplaySweetenerMobileModal,
    ] = useState(1);
    const { isAuthenticated } = useAuthentication();
    const { hasSubscription } = useSubscriptionsAndAllowedFeaturesContext();
    const { openModal, isModalVisible } = useModalContext();
    const isMobile = useBreakpoint('md');

    const hasPermissions =
        !hasSubscription &&
        isAuthenticated &&
        !getIsDAWPlugin() &&
        !isModalVisible.freeUserPaidDownloadUpsell &&
        isEligibleForSweetener;

    const handleOpen = useCallback(
        (event: MouseEvent): void => {
            event.preventDefault();

            if (hasPermissions) {
                setIsEligibleForSweetener(false);
                openModal.sweetenerUpsellModal();
                handleAnalytics();
            }
        },
        [hasPermissions, openModal],
    );

    useEffect(() => {
        document.body.addEventListener('mouseleave', handleOpen);

        return (): void => {
            document.body.removeEventListener('mouseleave', handleOpen);
        };
    }, [handleOpen]);

    useEffect(
        // display the mobile modal after the second time they the upsell modal and only once
        function openMobileModal() {
            if (
                hasPermissions &&
                !isModalVisible.sweetenerUpsellModal &&
                counterToDisplaySweetenerMobileModal === 2
            ) {
                openModal.sweetenerUpsellModal();
                handleAnalytics();
            } else if (isMobile && isEligibleForSweetener) {
                incrementCounterToDisplaySweetenerMobileModal(
                    counterToDisplaySweetenerMobileModal + 1,
                );
                setIsEligibleForSweetener(false);
            }
        },
        [
            counterToDisplaySweetenerMobileModal,
            hasPermissions,
            isEligibleForSweetener,
            isMobile,
            isModalVisible.sweetenerUpsellModal,
            openModal,
        ],
    );

    const handleAnalytics = (): void => {
        analytics.trackModalOpened({
            modalName: ModalName.Sweetener,
            interactionSource: InteractionSource.UpsellModal,
        });
    };

    const context = {
        setIsEligibleForSweetener,
    };

    return (
        <SweetenerUpsellModalContext.Provider value={context}>
            {children}
        </SweetenerUpsellModalContext.Provider>
    );
};

export default SweetenerUpsellModalProvider;
