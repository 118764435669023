import getStaticSeo from 'constants/staticSeo';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RoutePaths } from 'types/enums';
import { useLocalePrefixes } from 'hooks/useLocalePrefixes/useLocalePrefixes';
import { Browse } from './components/Browse/Browse';
import { Recommendation } from './components/Recommendation/Recommendation';
import { PackDetails } from './components/PackDetails/PackDetails';
import { RePackDetails } from './components/RePackDetails/RePackDetails';
import { ArtistDetails } from './components/ArtistDetails/ArtistDetails';
import { LabelDetails } from './components/LabelDetails/LabelDetails';
import { BrandtypeDetails } from './components/BrandtypeDetails/BrandtypeDetails';
import { DynamicMetadata } from './components/DynamicMetadata/DynamicMetadata';

const MetadataRouter: React.FC = ({ children }) => {
    const staticSeo = getStaticSeo();
    const localePrefixes = useLocalePrefixes();

    return (
        <>
            <Routes>
                {localePrefixes.map((locale) => (
                    <React.Fragment key={locale}>
                        <Route
                            path={`${locale}${RoutePaths.CreatorBeatMaker}/:hash`}
                            element={
                                <DynamicMetadata
                                    title={staticSeo.creatorShared.title}
                                    description={
                                        staticSeo.creatorShared.description
                                    }
                                    imageUrl={staticSeo.creatorShared.imageUrl}
                                    withWildcard={true}
                                />
                            }
                        />
                        <Route
                            path={`${locale}${RoutePaths.CreatorBeatMaker}`}
                            element={
                                <DynamicMetadata
                                    title={staticSeo.creator.title}
                                    description={staticSeo.creator.description}
                                    imageUrl={staticSeo.creator.imageUrl}
                                />
                            }
                        />
                        <Route
                            path={`${locale}${RoutePaths.Instruments}`}
                            element={
                                <DynamicMetadata
                                    title={staticSeo.instruments.title}
                                    description={
                                        staticSeo.instruments.description
                                    }
                                    imageUrl={staticSeo.instruments.imageUrl}
                                />
                            }
                        />
                        <Route
                            path={`${locale}${RoutePaths.Genres}`}
                            element={
                                <DynamicMetadata
                                    title={staticSeo.genres.title}
                                    description={staticSeo.genres.description}
                                    imageUrl={staticSeo.genres.imageUrl}
                                />
                            }
                        />
                        <Route
                            path={`${locale}${RoutePaths.Sfxs}`}
                            element={
                                <DynamicMetadata
                                    title={staticSeo.sfxs.title}
                                    description={staticSeo.sfxs.description}
                                    imageUrl={staticSeo.sfxs.imageUrl}
                                />
                            }
                        />
                        <Route
                            path={`${locale}${RoutePaths.Browse}`}
                            element={<Browse />}
                        />
                        <Route
                            path={`${locale}${RoutePaths.Genres}/:genres`}
                            element={<Browse />}
                        />
                        <Route
                            path={`${locale}${RoutePaths.Descriptors}/:descriptors`}
                            element={<Browse />}
                        />
                        <Route
                            path={`${locale}${RoutePaths.Instruments}/:instruments`}
                            element={<Browse />}
                        />
                        <Route
                            path={`${locale}${RoutePaths.Sfxs}/:sfsx`}
                            element={<Browse />}
                        />
                        <Route
                            path={`${locale}${RoutePaths.Recommendations}/:id`}
                            element={<Recommendation />}
                        />
                        <Route
                            path={`${locale}${RoutePaths.Packs}/:slug`}
                            element={<PackDetails />}
                        />
                        <Route
                            path={`${locale}${RoutePaths.Collections}/:slug`}
                            element={<RePackDetails />}
                        />

                        <Route
                            path={`${locale}${RoutePaths.Charts}`}
                            element={
                                <DynamicMetadata
                                    title={staticSeo.charts.title}
                                    description={staticSeo.charts.description}
                                    imageUrl={staticSeo.charts.imageUrl}
                                />
                            }
                        />
                        <Route
                            path={`${locale}${RoutePaths.Charts}/:slug`}
                            element={<RePackDetails />}
                        />
                        <Route
                            path={`${locale}${RoutePaths.Exclusives}`}
                            element={
                                <DynamicMetadata
                                    title={staticSeo.exclusives.title}
                                    description={
                                        staticSeo.exclusives.description
                                    }
                                    imageUrl={staticSeo.exclusives.imageUrl}
                                />
                            }
                        />

                        <Route
                            path={`${locale}${RoutePaths.Artists}/:slug`}
                            element={<ArtistDetails />}
                        />

                        <Route
                            path={`${locale}${RoutePaths.Labels}/:slug`}
                            element={<LabelDetails />}
                        />

                        <Route
                            path={`${locale}${RoutePaths.Category}/:slug`}
                            element={<BrandtypeDetails />}
                        />
                    </React.Fragment>
                ))}
                {/* Fallback to default Metadata */}
                <Route
                    path="*"
                    element={
                        <DynamicMetadata
                            title={staticSeo.default.title}
                            description={staticSeo.default.description}
                            imageUrl={staticSeo.default.imageUrl}
                        />
                    }
                />
            </Routes>
            {children}
        </>
    );
};

export default MetadataRouter;
