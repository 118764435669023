import { log } from 'utils/log';
import { PurchaseError } from '../Purchase.types';

export type HandlePurchaseError = (error?: PurchaseError) => void;

export const useHandlePurchaseError = ({
    showErrorNotification,
}: {
    showErrorNotification: () => void;
}) => {
    const handleError = (error?: PurchaseError) => {
        showErrorNotification();

        if (error) {
            log.error(error.message, error.id, error, error?.extraInfos);
        }
    };

    return handleError;
};
