import { APP_VERSION } from 'constants/app';
import { createHttpLink } from '@apollo/client';
import { getAnalyticsPlatformName } from 'helpers/executionEnvironment';
import { getXLandrAnalytics, AnalyticsApplication } from '@landr/core';
import { Languages } from 'types/enums/languages';
import { i18n } from 'utils/setupTranslations';
import { token as previewToken } from 'utils/preview';
import { featureFlagService } from 'helpers/featureFlagsService/feature-flag-service';
import { auth } from 'utils/auth/auth';
import { CONFIG } from 'utils/config';

const API_LANGUAGES: { [k in Languages]: string } = {
    [Languages.EN]: 'en-US',
    [Languages.FR]: Languages.FR,
    [Languages.ES]: Languages.ES,
};

export const customFetch = async (
    input: RequestInfo,
    options: RequestInit = {},
): Promise<Response> => {
    const token = await auth.getAccessToken();

    const authorization = token
        ? { authorization: `Bearer ${token}` }
        : undefined;

    const previewTokenHeader = previewToken
        ? { 'X-LANDR-PREVIEW': previewToken }
        : undefined;

    const modifiedOptions = {
        ...options,
        headers: {
            ...options.headers,
            'X-LANDR-ANALYTICS': JSON.stringify(
                getXLandrAnalytics(
                    AnalyticsApplication.Samples,
                    window,
                    getAnalyticsPlatformName(),
                ),
            ),
            'X-API-Languages': API_LANGUAGES[i18n.locale as Languages],
            'X-LANDR-FEATUREFLAGS': JSON.stringify(featureFlagService.get()),
            ...previewTokenHeader,
            ...authorization,
        },
    };

    return fetch(input, modifiedOptions);
};

export const httpLink = createHttpLink({
    fetch: customFetch,
    uri: CONFIG.VITE_GRAPHQL_API,
    headers: {
        'X-LANDR-CLIENT': `version=${APP_VERSION}, kind=${AnalyticsApplication.Samples}`,
    },
});

export const transportLink = httpLink;
