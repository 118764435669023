import { EventIds } from 'constants/eventIds';
import { PaginatedDownloadedSamples } from 'apollo/sample/sample.gql';
import { PaginatedDownloadedPacksAndRepacks } from 'apollo/packAndRepack/packAndRepack.gql';

const downloadsQueries = {
    samples: {
        query: PaginatedDownloadedSamples,
        pathToItems: 'paginatedDownloadedSamples',
        pathToTotalItems: 'totalSampleRecords',
        failureEvent: {
            eventId: EventIds.GetDownloadedSamplesFailed,
            message: 'Failed to get downloaded samples',
        },
    },
    packs: {
        query: PaginatedDownloadedPacksAndRepacks,
        pathToItems: 'paginatedDownloadedPacksAndRepacksV2',
        pathToTotalItems: 'totalRecords',
        failureEvent: {
            eventId: EventIds.GetDownloadedPacksAndRepacksFailed,
            message: 'Failed to get downloaded packs and repacks',
        },
    },
};

export default downloadsQueries;
