import { EventIds } from 'constants/eventIds';
import { UserFailureEvents } from 'constants/errorEvents';
import { LazyQueryHookOptions, useMutation } from '@apollo/client';
import {
    AcceptUserAgreementsMutation,
    AcceptUserAgreementsMutationVariables,
    Agreement,
    LegalAgreementKindEnum,
    User,
} from 'types/generated/graphql';
import {
    DoQuery,
    useQueryWhenAuthenticated,
} from 'hooks/useQueryWhenAuthenticated';
import { UserActions } from 'types';
import {
    AcceptUserAgreements,
    UserAgreements,
    UserCredits,
    UserProfile,
    SubscriptionsAndAllowedFeatures,
    AccountBalanceSummary,
    ActivateTrialSubscription,
} from './user.gql';

export type ApolloUserSamplesSubscription = {
    id: string;
    plan: {
        code: string;
    };
};

export const useUserProfile = (): {
    userProfile?: User;
    isUserLoading: boolean;
} => {
    const { data, loading } = useQueryWhenAuthenticated({
        query: UserProfile,
        errorConfig: UserFailureEvents[UserActions.GetUserProfileFailed],
    });

    return { userProfile: data?.userProfile, isUserLoading: loading };
};

export const useUserCredits = (
    options?: LazyQueryHookOptions,
): {
    userCredits?: { remaining: number };
    doQuery: DoQuery;
} => {
    const { data, doQuery } = useQueryWhenAuthenticated({
        query: UserCredits,
        errorConfig:
            UserFailureEvents[UserActions.GetUserRemainingCreditsFailed],
        options,
    });

    return {
        userCredits: data?.userCredits,
        doQuery: doQuery as DoQuery,
    };
};

export const useUserAgreements = (
    options?: LazyQueryHookOptions,
): {
    userAgreements?: Agreement[];
    doQuery: DoQuery;
} => {
    const { data, doQuery } = useQueryWhenAuthenticated({
        query: UserAgreements,
        errorConfig: UserFailureEvents[UserActions.GetUserAgreementsFailed],
        options,
    });

    return {
        userAgreements: data?.userAgreements?.agreements,
        doQuery: doQuery as DoQuery,
    };
};

// MUTATIONS

export const useAcceptUserAgreements = (
    kinds: LegalAgreementKindEnum[] = [],
) => {
    const [acceptAgreements] = useMutation<
        AcceptUserAgreementsMutation,
        AcceptUserAgreementsMutationVariables
    >(AcceptUserAgreements, {
        variables: { agreements: { kinds } },
        refetchQueries: [
            {
                query: UserAgreements,
            },
        ],
        awaitRefetchQueries: true,
    });

    return acceptAgreements;
};

export const useActivateTrialSubscription = () => {
    const [activateTrialSubscription, { loading, error, data }] = useMutation(
        ActivateTrialSubscription,
    );

    return {
        activateTrialSubscription,
        loading,
        error,
        data,
    };
};

export type SubscriptionsAndAllowedFeaturesQueryTypes = {
    hasStudioPlan: boolean;
    hasSamplesPlan: boolean;
    hasSubscription: boolean;
    samplePaidContentAccess: {
        isAllowed: boolean;
        unallowedReason?: {
            reason: string;
            unallowedByPlan?: string;
        };
    };
};

export const useSubscriptionsAndAllowedFeaturesQuery = (): {
    subscriptionsAndAllowedFeatures?: SubscriptionsAndAllowedFeaturesQueryTypes;
    isLoading: boolean;
    refetch: () => void;
} => {
    const { data, loading, doQuery } = useQueryWhenAuthenticated<{
        subscriptionsAndAllowedFeatures: SubscriptionsAndAllowedFeaturesQueryTypes;
    }>({
        query: SubscriptionsAndAllowedFeatures,
        errorConfig: {
            message: 'Failed to get user subscription and allowed features',
            id: EventIds.FailedGetSubscriptionsAndAllowedFeaturesQuery,
        },
    });

    return {
        subscriptionsAndAllowedFeatures: data?.subscriptionsAndAllowedFeatures,
        isLoading: loading,
        refetch: doQuery,
    };
};

export const useUserAccountStatusQuery = () => {
    const { data, loading, doQuery } = useQueryWhenAuthenticated({
        query: AccountBalanceSummary,
        errorConfig: UserFailureEvents[UserActions.GetUserAccountStatusFailed],
    });

    return {
        accountBalanceSummary: data?.accountBalanceSummary,
        isLoading: loading,
        refetch: doQuery,
    };
};
