import { EventIds } from 'constants/eventIds';
import { GraphQLError } from 'graphql';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { CONFIG } from 'utils/config';
import { log } from 'utils/log';

export enum ApolloErrors {
    UNAUTHENTICATED = 'UNAUTHENTICATED',
    UNAUTHORIZED = 'UNAUTHORIZED',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export const errorHandler = ({
    graphQLErrors,
    networkError,
    operation,
    response,
}: ErrorResponse): void => {
    const { headers } = operation.getContext();

    if (graphQLErrors) {
        graphQLErrors.forEach((error: GraphQLError) => {
            const { message, extensions } = error;
            const errorDefinition = `[GraphQL error]: ${message}`;

            if (extensions) {
                switch (extensions.code) {
                    case ApolloErrors.UNAUTHENTICATED:
                        log.warn(
                            errorDefinition,
                            EventIds.ApolloAuthenticationsError,
                            error,
                            {
                                operationName: operation?.operationName,
                                variables: operation?.variables,
                                correlationId: headers['X-CorrelationId'],
                            },
                        );
                        break;
                    case ApolloErrors.UNAUTHORIZED:
                        log.error(
                            errorDefinition,
                            EventIds.ApolloUnauthorizedError,
                            error,
                            {
                                operationName: operation?.operationName,
                                variables: operation?.variables,
                                correlationId: headers['X-CorrelationId'],
                            },
                        );
                        break;
                    case ApolloErrors.INTERNAL_SERVER_ERROR:
                        log.error(
                            errorDefinition,
                            EventIds.ApolloInternalServerError,
                            error,
                            {
                                operationName: operation?.operationName,
                                variables: operation?.variables,
                                correlationId: headers['X-CorrelationId'],
                            },
                        );
                        break;
                    default:
                        log.error(
                            errorDefinition,
                            EventIds.ApolloError,
                            error,
                            {
                                operationName: operation?.operationName,
                                variables: operation?.variables,
                                correlationId: headers['X-CorrelationId'],
                            },
                        );
                        break;
                }

                if (CONFIG.VITE_APP_ENV !== 'production') {
                    console.debug(error);
                    console.debug('Operation', operation);
                    console.debug('Response', response);

                    const { exception } = extensions;

                    if (exception) {
                        if ((exception as { url: string }).url) {
                            console.debug(
                                'ENDPOINT',
                                (exception as { url: string }).url,
                            );
                        }

                        if (
                            (exception as { errors: [string, unknown] }).errors
                        ) {
                            Object.entries(
                                (exception as { errors: [string, unknown] })
                                    .errors,
                            ).forEach(([key, error]) => {
                                console.debug(
                                    `API error for parameter [${key}]`,
                                    error,
                                );
                            });
                        }
                    }
                }
            } else {
                log.error(errorDefinition, EventIds.ApolloError, error, {
                    operationName: operation?.operationName,
                    variables: operation?.variables,
                    correlationId: headers['X-CorrelationId'],
                });
            }
        });
    }

    if (networkError) {
        log.error(
            `[Network error]: ${networkError}`,
            EventIds.ApolloNetworkError,
            networkError,
            {
                operationName: operation?.operationName,
                variables: operation?.variables,
                correlationId: headers['X-CorrelationId'],
            },
        );
    }
};

export const errorLink = onError(errorHandler);
