import React, { useContext, useCallback } from 'react';
import { AccountStatusEnum } from 'types/generated/graphql';
import {
    useSubscriptionsAndAllowedFeaturesQuery,
    useUserAccountStatusQuery,
} from 'apollo/user';
import { neverop } from 'utils';

export type SubscriptionsAndAllowedFeaturesContextType = {
    hasFetched: boolean;
    hasSamplesPlan: boolean;
    hasStudioPlan: boolean;
    hasSubscription: boolean;
    isPlanPaused: boolean;
    isPoorStanding: boolean;
    isTrialSubscription: boolean;
    unallowedByPlan?: string;
    refetchIfNeeded: ({
        isPoorStanding,
        isPlanPaused,
    }: {
        isPoorStanding: boolean;
        isPlanPaused: boolean;
    }) => void;
};

export const defaultSubscriptionsAndAllowedFeaturesContext = {
    hasFetched: false,
    hasSamplesPlan: false,
    hasStudioPlan: false,
    hasSubscription: false,
    isPlanPaused: false,
    isPoorStanding: false,
    isTrialSubscription: false,
    unallowedByPlan: undefined,
    refetchIfNeeded: neverop(
        'SubscriptionsAndAllowedFeaturesContext',
        'refetch',
    ),
};

export const SubscriptionsAndAllowedFeaturesContext =
    React.createContext<SubscriptionsAndAllowedFeaturesContextType>(
        defaultSubscriptionsAndAllowedFeaturesContext,
    );

// Exported for testing
export const useSubscriptionsAndAllowedFeaturesProvider = () => {
    const {
        isLoading: isSubscriptionAndAllowedFeaturesLoading,
        subscriptionsAndAllowedFeatures,
        refetch: refetchSubscriptionsAndAllowedFeatures,
    } = useSubscriptionsAndAllowedFeaturesQuery();

    // TODO: Remove this hook when allowed feature will support unallowedReason.reason = `PoorStanding`
    const {
        accountBalanceSummary,
        refetch: refetchUserAccountStatus,
        isLoading: accountBalanceSummaryLoading,
    } = useUserAccountStatusQuery();

    // This will never resolve to true when anonymous user
    const hasFetched = !!(
        !isSubscriptionAndAllowedFeaturesLoading &&
        subscriptionsAndAllowedFeatures &&
        !accountBalanceSummaryLoading &&
        accountBalanceSummary
    );

    const hasSamplesPlan = !!subscriptionsAndAllowedFeatures?.hasSamplesPlan;
    const hasStudioPlan = !!subscriptionsAndAllowedFeatures?.hasStudioPlan;
    const hasSubscription = !!subscriptionsAndAllowedFeatures?.hasSubscription;
    const samplePaidContentAccess =
        subscriptionsAndAllowedFeatures?.samplePaidContentAccess;

    const isPlanPaused =
        samplePaidContentAccess?.unallowedReason?.reason ===
        'PausedSubscription';

    const isPoorStanding =
        accountBalanceSummary?.accountingStatus ===
            AccountStatusEnum.PoorStanding ||
        accountBalanceSummary?.accountingStatus ===
            AccountStatusEnum.Collection;

    const isTrialSubscription =
        samplePaidContentAccess?.unallowedReason?.reason ===
        'TrialSubscription';

    const unallowedByPlan =
        samplePaidContentAccess?.unallowedReason?.unallowedByPlan;

    const refetchIfNeeded = useCallback(
        (props) => {
            if (props.isPoorStanding !== isPoorStanding) {
                refetchUserAccountStatus();
            }

            if (props.isPlanPaused !== isPlanPaused) {
                refetchSubscriptionsAndAllowedFeatures();
            }
        },
        [
            refetchSubscriptionsAndAllowedFeatures,
            refetchUserAccountStatus,
            isPoorStanding,
            isPlanPaused,
        ],
    );

    return {
        hasFetched,
        hasSamplesPlan,
        hasStudioPlan,
        hasSubscription,
        isPlanPaused,
        isPoorStanding,
        isTrialSubscription,
        unallowedByPlan,
        refetchIfNeeded,
    };
};

export const SubscriptionsAndAllowedFeaturesProvider: React.FC = ({
    children,
}) => {
    const context = useSubscriptionsAndAllowedFeaturesProvider();

    return (
        <SubscriptionsAndAllowedFeaturesContext.Provider value={context}>
            {children}
        </SubscriptionsAndAllowedFeaturesContext.Provider>
    );
};

export const useSubscriptionsAndAllowedFeaturesContext =
    (): SubscriptionsAndAllowedFeaturesContextType =>
        useContext(SubscriptionsAndAllowedFeaturesContext);

export default SubscriptionsAndAllowedFeaturesProvider;
