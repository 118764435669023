import getStaticSeo from 'constants/staticSeo';
import { useSeoMetaData } from 'hooks';
import { BrowseSeoType } from 'types/seoType';
import { RoutePaths } from 'types/enums';
import { useMatch } from 'react-router-dom';
import { useToLocalizedPath } from 'hooks/useToLocalizedPath/useToLocalizedPath';
import { t } from '@lingui/macro';

const useGetWildcardSeoFallback = () => {
    const toLocalizedPath = useToLocalizedPath();

    const matchInstruments = useMatch(
        toLocalizedPath(`/${RoutePaths.Instruments}/:wildcard`),
    );
    const matchGenres = useMatch(
        toLocalizedPath(`/${RoutePaths.Genres}/:wildcard`),
    );
    const matchSfxs = useMatch(
        toLocalizedPath(`/${RoutePaths.Sfxs}/:wildcard`),
    );

    const match = matchInstruments || matchGenres || matchSfxs;

    const wildcard = match?.params.wildcard;

    if (!wildcard) {
        return {};
    }

    const translatedWildcard = t({ id: wildcard });
    const lowerCaseWildcard = translatedWildcard.toLowerCase();

    let title;
    let description;
    let header;
    let byline;

    if (matchInstruments?.params.wildcard) {
        title = t({
            id: 'instrumentsWildcardTitleSeoFallback',
            message: '{translatedWildcard} Samples & Loops - 100% Royalty Free',
            values: { translatedWildcard },
        });

        description = t({
            id: 'instrumentsWildcardDescriptionSeoFallback',
            message:
                'Download 100% royalty free {lowerCaseWildcard} sample packs from leading labels featuring the best loops, beats and sounds for your music or creative projects. Get access to an expansive library of samples with your LANDR Studio subscription.',
            values: { lowerCaseWildcard },
        });

        header = t({
            id: 'instrumentsWildcardHeaderSeoFallback',
            message: '{translatedWildcard} Samples & Loops',
            values: { translatedWildcard },
        });

        byline = t({
            id: 'instrumentsWildcardBylineSeoFallback',
            message:
                "Explore our collection 100% royalty-free {lowerCaseWildcard} samples & loops. LANDR's {lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.",
            values: { lowerCaseWildcard },
        });
    } else if (matchGenres?.params.wildcard) {
        title = t({
            id: 'genresWildcardTitleSeoFallback',
            message: `{translatedWildcard} Samples & Loops - 100% Royalty Free`,
            values: { translatedWildcard },
        });

        description = t({
            id: 'genresWildcardDescriptionSeoFallback',
            message: `High quality & royalty free {lowerCaseWildcard} sample packs from the world's top audio sample labels & producers. Find the {lowerCaseWildcard} samples, loops, beats & one-shots you need for your music project at LANDR Samples.`,
            values: { lowerCaseWildcard },
        });

        header = t({
            id: 'genresWildcardHeaderSeoFallback',
            message: '{translatedWildcard} Samples & Loops',
            values: { translatedWildcard },
        });

        byline = t({
            id: 'genresWildcardBylineSeoFallback',
            message:
                "Explore our collection 100% royalty-free {lowerCaseWildcard} samples & loops. LANDR's {lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.",
            values: { lowerCaseWildcard },
        });
    } else if (matchSfxs?.params.wildcard) {
        title = t({
            id: 'sfxsWildcardTitleFallback',
            message:
                'Download {translatedWildcard} Samples & Sound Effects - 100% Royalty Free',
            values: { translatedWildcard },
        });

        description = t({
            id: 'sfxsWildcardDescriptionSeoFallback',
            message:
                'Download royalty free {lowerCaseWildcard} sound effects. Find high quality {lowerCaseWildcard} sounds & samples for your next creative project with LANDR in MP3 or WAV format.',
            values: { lowerCaseWildcard },
        });

        header = t({
            id: 'sfxsWildcardHeaderSeoFallback',
            message: '{translatedWildcard} Sound Effects',
            values: { translatedWildcard },
        });

        byline = t({
            id: 'sfxsWildcardBylineSeoFallback',
            message:
                "Explore our collection 100% royalty-free {lowerCaseWildcard} samples & loops. LANDR's {lowerCaseWildcard} sample packs are designed to inspire and elevate your music production, our high-quality sounds & beats provide endless creative possibilities across hundreds of genres.",
            values: { lowerCaseWildcard },
        });
    }

    const wildcardSeo = {
        header,
        byline,
        title,
        description,
    };

    return wildcardSeo;
};

const useBrowseSeo = (): {
    loading: boolean;
    metaData: BrowseSeoType;
} => {
    const { loading, seoMetaData } = useSeoMetaData({ withWildcard: false });

    const wildcardSeoFallback = useGetWildcardSeoFallback();

    const metaData = {
        ...getStaticSeo().browse,
        ...wildcardSeoFallback,
        ...(seoMetaData?.title && { title: seoMetaData.title }),
        ...(seoMetaData?.description && {
            description: seoMetaData.description,
        }),
        ...(seoMetaData?.header && { header: seoMetaData.header }),
        ...(seoMetaData?.byline && { byline: seoMetaData.byline }),
    };

    return { loading, metaData };
};

export default useBrowseSeo;
