import { EventIds } from 'constants/eventIds';
import { ApolloError, useQuery } from '@apollo/client';
import { GetFilters } from 'apollo/filters/filters.gql';
import { log } from 'utils';
import { FiltersQuery, FilterCategories } from 'types/generated/graphql';

export const defaultApiFilters: FilterCategories = {
    genres: [],
    instruments: [],
    sampleTypes: [],
    sfxs: [],
    tags: [],
};

const useApiFilters = (): {
    apiFilters: FilterCategories;
    loading: boolean;
} => {
    const { data, loading } = useQuery<FiltersQuery>(GetFilters, {
        notifyOnNetworkStatusChange: true,
        onError: (error: ApolloError) => {
            log.error(
                'Failed to fetch filters',
                EventIds.GetFiltersFailed,
                error,
            );
        },
    });

    return {
        apiFilters: data?.filters || defaultApiFilters,
        loading,
    };
};

export default useApiFilters;
