import {
    MutationFunctionOptions,
    ApolloCache,
    DefaultContext,
} from '@apollo/client';
import { FetchResult } from '@apollo/react-hooks';
import { useCallback } from 'react';
import {
    PutMilestonesMutation,
    PutMilestonesMutationVariables,
} from 'types/generated/graphql';

type PutUserMilestones = (
    options?:
        | MutationFunctionOptions<
              PutMilestonesMutation,
              PutMilestonesMutationVariables,
              DefaultContext,
              ApolloCache<any>
          >
        | undefined,
) => Promise<FetchResult<PutMilestonesMutation>>;

const usePersistedMilestones = ({
    putUserMilestones,
    persistedMilestones = [],
}: {
    putUserMilestones: PutUserMilestones;
    persistedMilestones?: string[];
}) => {
    const updatePersistedMilestone = (milestone: string) => {
        putUserMilestones({
            variables: {
                milestones: [...new Set([...persistedMilestones, milestone])],
            },
        });
    };

    const updateAllPersistedMilestones = useCallback(
        (milestones: string[]): void => {
            putUserMilestones({
                variables: {
                    milestones: [
                        ...new Set([...persistedMilestones, ...milestones]),
                    ],
                },
            });
        },
        [persistedMilestones, putUserMilestones],
    );

    return {
        updateAllPersistedMilestones,
        updatePersistedMilestone,
    };
};

export default usePersistedMilestones;
