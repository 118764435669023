import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ArtistBySlug } from 'apollo/artist/artist.gql';
import PageMetadata from 'components/PageMetadata';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import {
    ArtistBySlugQuery,
    ArtistBySlugQueryVariables,
} from 'types/generated/graphql';

export const ArtistDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data, loading, error } = useQuery<
        ArtistBySlugQuery,
        ArtistBySlugQueryVariables
    >(ArtistBySlug, {
        variables: { slug: getSanitizeSlug(slug) },
    });

    if (loading) {
        return null;
    }

    if (!data?.artistBySlug || error) {
        return <PageMetadata />;
    }

    const { artistBySlug } = data;

    return (
        <PageMetadata
            title={artistBySlug?.seoMetadata?.title}
            description={artistBySlug?.seoMetadata?.description}
            imageUrl={artistBySlug?.artworkUrl}
        />
    );
};
