import { EventIds } from 'constants/eventIds';
import { MultiDownloadId } from 'constants/downloads';
import {
    CreatorDownloadSource,
    DownloadableItem,
    DownloadableItemSource,
} from 'types';
import { DownloadType } from 'types/enums';
import { log } from 'utils/log';
import { isPack } from 'types/typeGuards/isPack';
import { isRepack } from 'types/typeGuards/isRepack';
import { isSample } from 'types/typeGuards/isSample';
import { isCreatorDownloadSource } from 'types/typeGuards/isCreatorDownloadSource';
import { isUserCollection } from 'types/typeGuards/isUserCollection';
import { Pack, Repack, Sample, UserCollection } from 'types/generated/graphql';

function isStringArray(item: DownloadableItemSource): item is string[] {
    return (
        Array.isArray(item) &&
        item.every((element) => typeof element === 'string')
    );
}

const DownloadableItemInitialState = {
    type: DownloadType.Unknown,
    id: '',
    itemIds: [],
    isDownloaded: false,
    isPremium: false,
};

/**
 *
 * @param item - could be a Sample, Pack, RePack or an array of Sample IDs for multi-downloads
 * @return DownloadableItem - an item ready to be downloaded
 */
export const getDownloadableItem = (
    item: DownloadableItemSource | null | undefined,
): DownloadableItem => {
    if (!item) {
        return DownloadableItemInitialState;
    }

    switch (true) {
        case isStringArray(item) && item.length === 1:
            return {
                type: DownloadType.Sample,
                id: (item as string[])[0],
                itemIds: item as string[],
                isDownloaded: false,
                isPremium: false,
            };

        case isStringArray(item) && item.length > 1:
            return {
                type: DownloadType.Multi,
                id: MultiDownloadId,
                itemIds: item as string[],
                isDownloaded: false,
                isPremium: false,
            };

        case isPack(item):
            return {
                type: DownloadType.Pack,
                id: (item as Pack).guid,
                itemIds: [(item as Pack).guid],
                isDownloaded: (item as Pack).isDownloaded,
                isFree: (item as Pack).isFree,
                artUrl: (item as Pack).artworkUrl,
                isPremium: !!(item as Pack)?.premiumCodes?.length,
                name: (item as Pack).name,
            };

        case isRepack(item):
            return {
                type: DownloadType.Repack,
                id: (item as Repack).guid,
                itemIds: [(item as Repack).guid],
                isDownloaded: (item as Repack).isDownloaded,
                isFree: (item as Repack).isFree,
                artUrl: (item as Repack).artworkUrl,
                isPremium: !!(item as Repack)?.premiumCodes?.length,
                name: (item as Repack).name,
            };

        case isSample(item):
            return {
                type: DownloadType.Sample,
                id: (item as Sample).guid,
                itemIds: [(item as Sample).guid],
                isDownloaded: (item as Sample).isDownloaded,
                artUrl: (item as Sample).pack?.artworkUrl,
                isPremium: false,
                name: (item as Sample).name,
            };

        case isCreatorDownloadSource(item):
            return {
                type: (item as CreatorDownloadSource).type,
                id:
                    (item as CreatorDownloadSource).samples.length > 1 &&
                    (item as CreatorDownloadSource).type !==
                        DownloadType.CreatorMixdown
                        ? MultiDownloadId
                        : (item as CreatorDownloadSource).samples[0].guid,
                itemIds: (item as CreatorDownloadSource).samples.map(
                    (sample) => sample.guid,
                ),
                samples: (item as CreatorDownloadSource).samples.map(
                    (sample) => ({
                        id: sample.guid,
                        gainDb: sample.gainDb,
                        semitoneShift: sample.semitoneShift,
                        timeFactor: sample.timeFactor,
                    }),
                ) as any,
                isDownloaded: (item as CreatorDownloadSource).isDownloaded,
                targetMeta: (item as CreatorDownloadSource).targetMeta,
                isPremium: false,
            };

        case isUserCollection(item):
            return {
                type:
                    ((item as UserCollection)?.sampleCount || 0) > 1
                        ? DownloadType.CollectionMulti
                        : DownloadType.Collection,
                id: (item as UserCollection).guid ?? '',
                itemIds: new Array((item as UserCollection).sampleCount).fill(
                    (item as UserCollection).guid,
                ),
                isDownloaded: false, // UserCollection doesn't have isDownloaded property
                isPremium: false,
            };

        default:
            log.error(
                `Trying to generate a DownloadableItem from an invalid item`,
                EventIds.UnknownDownloadType,
                undefined,
                {
                    item,
                },
            );

            return {
                type: DownloadType.Unknown,
                id: '',
                itemIds: [],
                isDownloaded: false,
                isPremium: false,
            };
    }
};
