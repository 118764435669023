import getStaticSeo from 'constants/staticSeo';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSampleById } from 'apollo/sample';
import { DynamicMetadata } from '../DynamicMetadata/DynamicMetadata';

export const Recommendation = () => {
    const { id } = useParams<{ id: string }>();
    const { loading, sample } = useSampleById(id);
    const staticSeo = getStaticSeo();

    if (loading || !sample) {
        return null;
    }

    return (
        <DynamicMetadata
            title={staticSeo.default.title}
            description={staticSeo.default.description}
            imageUrl={sample.pack?.artworkUrl}
            withWildcard={true}
        />
    );
};
