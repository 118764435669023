import React from 'react';
import PageMetadata from 'components/PageMetadata';
import { useSeoMetaData } from 'hooks/useSeoMetaData';

export const DynamicMetadata = ({
    title: titleFallback,
    description: descriptionFallback,
    imageUrl,
    withWildcard,
}: {
    title: string;
    description: string;
    imageUrl?: string;
    withWildcard?: boolean;
}) => {
    const { loading, seoMetaData } = useSeoMetaData({ withWildcard });

    if (loading) {
        return null;
    }

    return (
        <PageMetadata
            title={seoMetaData?.title ?? titleFallback}
            description={seoMetaData?.description ?? descriptionFallback}
            imageUrl={imageUrl}
        />
    );
};
