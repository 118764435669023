import { DownloadableItem } from 'types';
import { OpenModal } from 'contexts/ModalContext/ModalContext.type';

export const handleNotEnoughCredits = ({
    item,
    hasSubscription,
    openModal,
}: {
    item: DownloadableItem;
    hasSubscription: boolean;
    openModal: OpenModal;
}) => {
    if (hasSubscription) {
        openModal.paidUserPaidDownloadUpsell();
    } else {
        openModal.freeUserPaidDownloadUpsell();
    }
};
