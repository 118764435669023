import { EventIds } from 'constants/eventIds';
import { RetryLink } from '@apollo/client/link/retry';
import { log } from 'utils/log';

const InitialDelayInMs = 500;
const MaxAttempts = 3;
// Exponential backoff doubles the delay for each attempt.
// We add the initial delay to prevent last attempt rejection.
const MaxDelay = Math.pow(2, MaxAttempts) * InitialDelayInMs + InitialDelayInMs;

// Which graphql operation we want to rety on network error
const retryableOperations = new Set([
    'SampleStream',
    'Pack',
    'PaginatedSamples',
    'PaginatedPackSamples',
    'PaginatedRepackSamples',
    'MerchZone',
    'UserCredits',
    'Repack',
    'PaginatedPacks',
    'AccountBalanceSummary',
    'Milestones',
    'UserHasSubscription',
    'Sample',
    'PaginatedRecommendedSamples',
    'UserAgreements',
    'ChartsTopPacks',
    'ContentGroup',
    'UserProfile',
    'GetUserRecentSearch',
    'SamplesCostPreview',
]);

const delay: RetryLink.Options['delay'] = {
    initial: InitialDelayInMs,
    max: MaxDelay,
    jitter: true,
};

const attempts: RetryLink.Options['attempts'] = (count, operation, error) => {
    const isRetryableOperation = retryableOperations.has(
        operation.operationName,
    );
    if (count > MaxAttempts || !isRetryableOperation) {
        return false;
    }

    const hasError = !!error;
    const { headers } = operation.getContext();
    if (hasError) {
        log.info(
            `[Operation]: ${operation?.operationName}, [Retry count]: ${count}, [Error]: ${error}`,
            EventIds.ApolloNetworkError,
            {
                error: error,
                operationName: operation?.operationName,
                variables: operation?.variables,
                correlationId: headers['X-CorrelationId'],
            },
        );
    } else if (count > 1) {
        log.info(
            `[Operation]: ${operation?.operationName} succeeded after ${count} retries`,
            EventIds.ApolloNetworkError,
            {
                operationName: operation?.operationName,
                variables: operation?.variables,
                correlationId: headers['X-CorrelationId'],
            },
        );
    }

    return hasError && isRetryableOperation;
};

export const retryLink = new RetryLink({ delay, attempts });
