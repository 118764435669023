import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sendLogout } from 'helpers';
import { auth } from '../../utils/auth/auth';

export type User = {
    city?: string;
    country: string;
    email: string;
    firstname: string;
    displayName?: string;
    homePage?: string;
    isLoggedOnBehalf?: boolean;
    lastname?: string;
    libraryId?: string;
    preferredCulture?: string;
    profilePicture72Url?: string;
    state?: string;
    userId: string;
};

/**
 * Exporting for tests only
 * Do not use directly.
 */
export const useAuthenticationEffects = (
    isDAWPlugin: boolean,
    isDesktopApp: boolean,
    sendAuthToken: (token: string) => void,
) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState<User | null | undefined>();

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            // Redirect to login flow "forced"
            const isAuthenticatedLocal = await auth.isAuthenticated();

            if (!isAuthenticatedLocal && (isDAWPlugin || isDesktopApp)) {
                auth.login({
                    forceLoginPrompt: true,
                });
            }

            const token = await auth.getAccessToken();

            if (isDAWPlugin) {
                sendAuthToken(token ?? '');
            }

            const appState = auth.getAppState();

            if (appState && appState.targetUrl) {
                navigate(appState.targetUrl, { replace: true });
            }
        };

        initialize();
    }, []);

    useEffect(() => {
        async function getUser() {
            const authUser = await auth.getUser();
            setUser(authUser);
        }

        getUser();
    }, []);

    useEffect(() => {
        async function updateAuthTokenForDawPlugin() {
            if (isDAWPlugin) {
                const token = await auth.getAccessToken();

                sendAuthToken(token ? token : '');
            }
        }

        updateAuthTokenForDawPlugin();
        // Only run on location change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const login = useCallback(() => {
        auth.login();
    }, []);

    const signup = useCallback(() => {
        auth.signup();
    }, []);

    const logout = useCallback(() => {
        if (isDAWPlugin) {
            sendLogout();
        }

        auth.logout();
    }, []);

    const isAuthenticated = !!user;
    const isLoading = user === undefined;
    const isGuestUser = user === null;

    return {
        isLoading,
        isAuthenticated,
        isGuestUser,
        user,
        login,
        signup,
        logout,
    };
};

export default useAuthenticationEffects;
