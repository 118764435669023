import { EventIds } from 'constants/eventIds';
import { useMutation } from '@apollo/client';
import { useQueryWhenAuthenticated } from 'hooks/useQueryWhenAuthenticated';
import {
    PutMilestonesMutation,
    PutMilestonesMutationVariables,
} from 'types/generated/graphql';
import { Milestones, PutMilestones } from './milestones.gql';

export const usePutUserMilestones = () => {
    const [putUserMilestones] = useMutation<
        PutMilestonesMutation,
        PutMilestonesMutationVariables
    >(PutMilestones);

    return putUserMilestones;
};

export const useUserMilestones = (): {
    milestones: string[] | undefined;
    loading: boolean;
} => {
    const { data = { milestones: undefined }, loading } =
        useQueryWhenAuthenticated({
            query: Milestones,
            errorConfig: {
                message: `Failed to get User Milestones`,
                id: EventIds.FailedToGetUserMilestones,
            },
            options: {
                fetchPolicy: 'no-cache',
            },
        });

    return { milestones: data.milestones, loading };
};
