import { gql } from '@apollo/client';

export const LABEL_EXCERPT_FRAGMENT = gql`
    fragment LabelExcerptFragment on Label {
        guid
        name
        slug
        isExclusive
        artworkUrl
    }
`;

export const LABEL_DETAIL_FRAGMENT = gql`
    fragment LabelDetailFragment on Label {
        ...LabelExcerptFragment
        name
        description
        artworkUrl
        coverUrl
        isExclusive
        seoMetadata {
            title
            description
        }
        sampleCount
        packCount
    }
    ${LABEL_EXCERPT_FRAGMENT}
`;
