import { UserActions } from 'types/actions';
import { EventTypeList } from 'types/eventTypeList';
import { EventIds } from './eventIds';

export const UserFailureEvents: EventTypeList = {
    [UserActions.GetUserCurrencyFailed]: {
        id: EventIds.GetUserCurrencyFailed,
        message: 'Failed to get user currency',
    },
    [UserActions.GetUserProfileFailed]: {
        id: EventIds.GetUserProfileFailed,
        message: 'Failed to get user profile',
    },
    [UserActions.GetUserAccountStatusFailed]: {
        id: EventIds.GetUserAccountStatusFailed,
        message: 'Failed to get user account status',
    },
    [UserActions.GetUserLocationFailed]: {
        id: EventIds.GetUserLocationFailed,
        message: 'Failed to get user location',
    },
    [UserActions.GetUserRemainingCreditsFailed]: {
        id: EventIds.GetUserRemainingCreditsFailed,
        message: 'Failed to get user remaining credits',
    },
    [UserActions.GetUserAgreementsFailed]: {
        id: EventIds.GetUserAgreementsFailed,
        message: 'Failed to get user agreements',
    },
    [UserActions.GetAccountSummaryFailed]: {
        id: EventIds.GetUserAccountStatusFailed,
        message: 'Failed to get user account summary',
    },
};
