import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PackBySlugOrGuid } from 'apollo/pack/pack.gql';
import PageMetadata from 'components/PageMetadata';
import { getSanitizeSlug } from 'helpers/getSanitizeSlug';
import {
    PackBySlugOrGuidQuery,
    PackBySlugOrGuidQueryVariables,
} from 'types/generated/graphql';

export const PackDetails = () => {
    const { slug } = useParams<{ slug: string }>();
    const { data, loading, error } = useQuery<
        PackBySlugOrGuidQuery,
        PackBySlugOrGuidQueryVariables
    >(PackBySlugOrGuid, {
        variables: { packSlugOrGuid: getSanitizeSlug(slug) },
    });

    if (loading) {
        return null;
    }

    if (!data?.pack || error) {
        return <PageMetadata />;
    }

    const { pack } = data;

    return (
        <PageMetadata
            imageUrl={pack?.artworkUrl}
            title={pack?.seoMetadata?.title}
            description={pack?.seoMetadata?.description}
        />
    );
};
