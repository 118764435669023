import { gql } from '@apollo/client';
import { ARTIST_EXCERPT_FRAGMENT } from 'apollo/artist/artist.fragment';
import { BRANDTYPE_EXCERPT_FRAGMENT } from 'apollo/brandtype/brandtype.fragment';
import { GENRE_FRAGMENT, SFX_FRAGMENT } from 'apollo/filters/filters.fragment';
import { LABEL_EXCERPT_FRAGMENT } from 'apollo/label/label.fragment';

export const REPACK_EXCERPT_FRAGMENT = gql`
    fragment RepackExcerptFragment on Repack {
        artworkUrl
        creatorSession
        guid
        isDownloaded
        isExclusive
        isFavorited
        isFree
        name
        packState
        premiumCodes
        slug
        subType
        type
    }
`;

export const REPACK_DETAIL_FRAGMENT = gql`
    fragment RepackDetailFragment on Repack {
        ...RepackExcerptFragment
        backgroundColor
        creatorSession
        description
        foregroundColor
        sampleCount
        mainGenre {
            ...GenreFragment
        }
        genres {
            ...GenreFragment
        }
        sfxs {
            ...SfxFragment
        }
        artist {
            ...ArtistExcerptFragment
        }
        label {
            ...LabelExcerptFragment
        }
        brandtype {
            ...BrandtypeExcerptFragment
        }
        seoMetadata {
            title
            description
        }
    }
    ${REPACK_EXCERPT_FRAGMENT}
    ${ARTIST_EXCERPT_FRAGMENT}
    ${LABEL_EXCERPT_FRAGMENT}
    ${GENRE_FRAGMENT}
    ${SFX_FRAGMENT}
    ${BRANDTYPE_EXCERPT_FRAGMENT}
`;
